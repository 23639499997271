// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/tmp/build_257ecd69f06b4158799326ac84825f0e/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-apps-tsx": () => import("/tmp/build_257ecd69f06b4158799326ac84825f0e/src/pages/apps.tsx" /* webpackChunkName: "component---src-pages-apps-tsx" */),
  "component---src-pages-contact-tsx": () => import("/tmp/build_257ecd69f06b4158799326ac84825f0e/src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("/tmp/build_257ecd69f06b4158799326ac84825f0e/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-internal-tools-whitepaper-tsx": () => import("/tmp/build_257ecd69f06b4158799326ac84825f0e/src/pages/internal-tools-whitepaper.tsx" /* webpackChunkName: "component---src-pages-internal-tools-whitepaper-tsx" */),
  "component---src-pages-internal-tools-tsx": () => import("/tmp/build_257ecd69f06b4158799326ac84825f0e/src/pages/internal-tools.tsx" /* webpackChunkName: "component---src-pages-internal-tools-tsx" */),
  "component---src-pages-work-tsx": () => import("/tmp/build_257ecd69f06b4158799326ac84825f0e/src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */)
}

exports.data = () => import("/tmp/build_257ecd69f06b4158799326ac84825f0e/.cache/data.json")

